import React from "react";
import styled from "styled-components";

function ComingSoon() {
    return (
        <Container>
            <LogoContainer>
                <img
                    src={require("../assets/images/makt-logo.svg").default}
                    alt="logo"
                />
            </LogoContainer>
            <Button>www.makt.ai</Button>
            <Text>Coming soon</Text>
        </Container>
    );
}

export default ComingSoon;
const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const LogoContainer = styled.div`
    width: 140px;
    margin-bottom: 40px;
    img {
        width: 100%;
        display: block;
    }
`;
const Button = styled.div`
    background-color: #00abc5;
    border-radius: 30px;
    color: #fff;
    padding: 8px 40px;
    font-size: 20px;
`;
const Text = styled.h1`
    font-size: 45px;
    text-transform: capitalize;
    color: #c4dfe3;
`;
